import Permissions from '@configs/permissions';
import { getAccessToken, getPermissions } from '@/utils/account-localstorage';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return getAccessToken() != null;
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 */
export const getHomeRouteForLoggedInUser = () => {
  let permissionSet = new Set();

  if (getPermissions() != null) {
    getPermissions().forEach(permission => {
      permissionSet.add(permission.subject);
    });
  }

  let result = { name: 'auth-login' };

  if (permissionSet.has(Permissions.MANAGEMENT)) {
    result = { name: 'management-p&l' };
  } else if (permissionSet.has(Permissions.FULFILLMENT)) {
    result = { name: 'fulfillment-management' };
  } else if (permissionSet.has(Permissions.SORTATION)) {
    result = { name: 'sortation-management' };
  } else if (permissionSet.has(Permissions.K_PARCEL)) {
    result = { name: 'k-parcel-operations' };
  } else if (permissionSet.has(Permissions.CRM)) {
    result = { name: 'crm-deals' };
  } else if (permissionSet.has(Permissions.ERP)) {
    result = { name: 'erp-organisation-profile' };
  } else if (permissionSet.has(Permissions.JIRA)) {
    result = { name: 'jira-tasks' };
  } else if (permissionSet.has(Permissions.SETTING)) {
    result = { name: 'setting-account' };
  }

  return result;
}
